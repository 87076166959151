import React from "react";
import { motion } from "framer-motion";

const svgVariants = {
  hidden: { rotate: -10 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};
const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
      delay: 0.7,
    },
  },
};
export default function MatchCurveSvg() {
  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      whileInView="visible"
      className="w-full"
      width="192"
      height="9"
      viewBox="0 0 192 9"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        whileInView="visible"
        d="M1 7.63111C6.85166 7.63111 12.4393 2 20.1525 2C27.8671 2 34.7823 7.63111 42.4956 7.63111C50.2102 7.63111 56.3274 2 64.3076 2C72.2878 2 78.9375 7.63111 89.5773 7.63111C100.217 7.63111 103.942 2 113.252 2C122.563 2 133.201 7.63111 141.98 7.63111C150.758 7.63111 154.748 2 166.451 2C178.156 2 181.879 7.63111 190.657 7.63111"
        stroke="#FF8E5E"
        strokeWidth="2.11198"
        strokeLinecap="round"
      />
    </motion.svg>
  );
}
