import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

const svgVariants = {
  hidden: { rotate: 0 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};
const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export default function PerMatchCurveSvg() {
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();
  const svgRef = useRef(null);

  useEffect(() => {
    let isMounted = true; // Tracks if the component is mounted

    const observer = new IntersectionObserver(
      (entries) => {
        if (!isMounted) return;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            controls.start("visible");
          } else {
            setIsInView(false);
            controls?.start("hidden");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    return () => {
      isMounted = false; // Prevent updates if the component is unmounted
      if (svgRef.current) {
        observer.unobserve(svgRef.current);
      }
    };
  }, [controls]);
  return (
    <motion.svg
      ref={svgRef}
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      width="154"
      height="98"
      viewBox="0 0 154 98"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="hidden md:flex lg:flex"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate={controls}
        d="M155.131 87.6445C154.396 88.25 148.512 94.2242 148.462 97.178C148.4 96.8713 145.356 92.8968 140.945 90.7001"
        stroke="#BCBCBC"
        strokeWidth="2.24"
      />
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate={controls}
        d="M1.5 9.7531C9 -1.8749 83.535 -5.99189 75.512 31.3791C72.749 44.2501 52.999 26.2501 76.571 19.1791C87.814 15.8061 125.5 23.5001 105.607 59.5851C100.563 68.7351 90.124 54.7501 105.105 48.0861C120.086 41.4221 147 57.0001 148.125 93.7501"
        stroke="#BCBCBC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
}
