import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

const svgVariants = {
  hidden: { rotate: 0 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};
const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export default function PerMatchArrowSvg() {
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();
  const svgRef = useRef(null);

  useEffect(() => {
    let isMounted = true; // Tracks if the component is mounted

    const observer = new IntersectionObserver(
      (entries) => {
        if (!isMounted) return;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            controls.start("visible");
          } else {
            setIsInView(false);
            controls?.start("hidden");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    return () => {
      isMounted = false; // Prevent updates if the component is unmounted
      if (svgRef.current) {
        observer.unobserve(svgRef.current);
      }
    };
  }, [controls]);
  return (
    <motion.svg
      ref={svgRef}
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      width="30"
      height="21"
      viewBox="0 0 30 21"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="mt-[30px] dark-txt"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate={controls}
        d="M0.9375 11.5809H29.0625M29.0625 11.5809C25.9375 8.38638 19.5134 4.25642 18.0134 0.970703M29.0625 11.5809L18.0134 19.7207"
        stroke="#1D1E22"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
}
